@media only screen and (min-width: 1000px) {
    .infocircles {
        background-image: url("/src/components/img/infocircles.png");
        background-position: center -18vh;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        flex-direction: column;
        width: 100vw;
        height: 106vh;
        margin-left: -20vw;
        margin-right: 10vw;
        padding-left: calc(10px + 27vw);
        padding-top: 5vh;
        align-items: center;
        margin-bottom: -27vh;
    }

    .circleheading {
        font-weight: bold;
        margin-bottom: -1vh;
    }



    .description2,
    .description3,
    .description4 {
        width: 25vw;
        margin-bottom: calc(-150px + 16vh);
    }

    .description2,
    .description4 {
        text-align: left;
        margin-left: 37vw;
    }

    .description3 {
        text-align: right;
        margin-right: 37vw;
    }

    .description2 {
        margin-top: 11vh;
    }
}

@media only screen and (max-width: 1000px) {
    .infocircles {
        background-image: url("/src/components/img/mobilesquares.png");
        background-position: -5vw;
        background-repeat: no-repeat;
        background-size: 50vw;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: flex-start;
        justify-content: center;
        width: 100vw;
        height: 80vh;


    }

    .infocircles h5 {
        margin-left: 50px;
        margin-right: 50px;

    }

    .description2,
    .description3,
    .description4 {
        text-align: left;
        height: 15vh;
        margin-left: 22vw;
        width: 85%;
        padding-top: calc(10px + 3vh);
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;

        justify-content: center;

    }

    .circleheading {
        font-weight: bold;
    }





}