.servicesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: left;
    width: 100%;
    height: 100%;
    padding-left: 10vw;
}

.subhead {
    max-width: 40vw;
}

.servicebkg {
    background-image: url('/src/components/img/PULPINDUSRTY.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.5;
    min-height: 65vh;
    margin-top: 10vh;
}

.serveYallo {
    background-image: url('/src/components/img/threemen.jpeg');
    opacity: 0.8;
}

.serveImg {
    background-image: url('/src/components/img/ENERGYINDUSTRY.avif');
}

.externalScn {
    background-image: url('/src/components/img/wallscn2.gif');
}

.internalScn {
    background-image: url('/src/components/img/internalSCN.gif');
}




.srvbtn span {
    align-items: center;
    background: #28282800;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    height: 80%;
    transition: 0.5s ease;
    width: 100%;
}

.srvbtn:hover {
    background: linear-gradient(#494949, #363636);
}

.boilerScanXimg {
    background-image: url('/src/components/img/boilerscanx.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 2px;
    height: 95%;
    width: 100%;
}

.corMapimg {
    background-image: url('/src/components/img/corrosionmap.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 2px;
    height: 95%;
    width: 100%;
}

.neardrumimg {
    background-image: url('/src/components/img/neardrum.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 2px;
    height: 95%;
    width: 100%;
}

.irisimg {
    background-image: url('/src/components/img/iris.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 2px;
    height: 95%;
    width: 100%;
}

.phasedarrayimg {
    background-image: url('/src/components/img/phasedarray2.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 2px;
    height: 95%;
    width: 100%;
}

.eddyCurrentimg {
    background-image: url('/src/components/img/eddycurrent.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 2px;
    height: 95%;
    width: 100%;
}





.arrow {
    background-image: url('/src/components/img/arrow.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 50%;
    height: 50%;
    margin-left: -0px;
}



@media only screen and (min-width: 1000px) {
    .inspectedobj {
        background-image: url('/src/components/img/inspectedobjects.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: calc(230px + 55vh);
        width: 65vw;
        margin-left: -11vw;
        zoom: 1.2;
    }
    .servicesContainer h3 {
        text-align: left;
        padding-bottom: 4vh;
        margin-top: -7vh;
    }

    .srvlinks {
        height: 20vh;
        padding-bottom: 20vh;
        width: 80vw;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: calc(5px + 4vw);
        grid-row-gap: -5vh;
        justify-items: center;
        align-content: center;
    }
    .circlebtn {
        background-image: linear-gradient(to right, #00CCFF -10%, #224078 110%);
        height: 40px;
        width: 40px;
        border-radius: 200em;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(0deg);
        font-size: 30px;
    }


    .srvcontent {
        min-height: 90vh;
        max-width: 50vw;
        padding-left: 0;
        align-self: left;
        display: flex;
        flex-direction: column;
        align-content: left;
        justify-content: center;
        text-align: left;
        z-index: 8;
        position: relative;
        padding-bottom: 3vh;
    }

    .srvbtn {
        background-color: #272727;
        box-sizing: border-box;
        color: white;
        display: grid;
        grid-template-columns: 1.3fr 1.5fr 0.8fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        align-items: center;
        justify-items: center;
        height: calc(20px + 10vh);
        letter-spacing: 1px;
        padding: 2px;
        text-decoration: none;
        z-index: 2;
        font-family: tit-reg;
        font-size: calc(13px + 0.3vw);
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 32px;
        text-align: center;
        margin-top: 4.5vh;
        width: calc(30px + 22vw);
        border: none;
        cursor: pointer;
    }

    .industriesServe {
        display: grid;
        grid-template-areas: 'right left';
        grid-template-columns: 30% 70%;
        grid-template-rows: 100%;
        height: 70vh;
        width: 100%;
    }

    .industryList {
        list-style-type: style circle;
        padding-left: 1.3vw;
        text-align: left;
        font-family: tit-reg;
        font-size: calc(8px + 0.6vw);
        letter-spacing: 0.06em;
        line-height: 4.5vh;
        font-weight: normal;
    }

    .industriesServe h3 {
        margin: 0;
        text-align: left;
    }

    .serveYallo {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        align-self: flex-end;
        grid-area: yallo;
    }

    .serveImg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        align-self: flex-end;
        justify-self: flex-end;
        grid-area: img;
        z-index: 5;
    }

    .serveTxt {
        grid-area: txt;
        width: 80%;
        text-align: right;
        font-family: tit-reg;
        font-style: italic;
        line-height: 24px;
        font-weight: normal;
        font-size: calc(8px + 0.6vw);
        letter-spacing: 0.06em;
        padding: 1em;
    }

    .rightServe {
        grid-area: left;
        display: grid;
        grid-template-areas: 'img yallo'
            'txt yallo';
        grid-template-columns: 29vw 23.5vw;
        grid-template-rows: 50% 20%;
        grid-gap: 20px;
        align-items: right;
        justify-items: right;
    }

    .leftServe {
        grid-area: right;

    }

    .internalExternalCont {
        height: fit-content;
        width: 90%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 0px;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        padding-bottom: 10vh;
        margin-top: -2vh;
    }

    .externalScn {
        margin-top: 3%;
        height: 55vh;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .extImgDsc {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 5;
        background-color: #0f0f0fa3;
        width: 38.1vw;
        height: 10vh;
        margin-top: 45vh;
        text-align: left;
        padding-left: 20px;
        justify-content: center;
    }

    .extImgDsc h5,
    .extImgDsc h2 {
        margin: 0;
        padding: 0;
    }

    .internalScn {
        margin-top: 3%;
        height: 55vh;
        width: 100%;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .intImgDsc {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 5;
        background-color: #0f0f0fa3;
        width: 38.1vw;
        height: 10vh;
        margin-top: 45vh;
        text-align: right;
        padding-right: 20px;
        justify-content: center;
    }

    .intImgDsc h5,
    .intImgDsc h2 {
        margin: 0;
        padding: 0;
    }

    .inspectedObjects {
        display: grid;
        grid-template-areas: 'right left';
        grid-template-columns: 30vw 70vw;
        grid-template-rows: 60vh;
        height: 85vh;
        width: 100vw;
        padding-bottom: 10vh;
        margin-top: 50px;
    }

    .inspectedObjects h3 {
        margin: 0;
        text-align: left;
    }

    .objTxt {
        width: 85%;
        font-family: tit-reg;
        font-style: italic;
        line-height: 24px;
        font-weight: normal;
        font-size: calc(8px + 0.6vw);
        letter-spacing: 0.06em;
        text-align: left;
    }

    .rightObj {
        grid-area: left;
        display: flex;
        align-items: right;
        justify-items: right;
    }

    .leftObj {
        grid-area: right;
    }
}

@media only screen and (max-width: 1000px) {
.servicebkg{
    margin-top: 12vh;
}

    .srvcontent {
        min-height: 90vh;
        max-width: 85vw;
        padding-left: 0;
        align-self: left;
        display: flex;
        flex-direction: column;
        align-content: left;
        justify-content: center;
        text-align: left;
        z-index: 8;
        position: relative;
        margin-bottom: -30px;
        padding-bottom: 1vh;
    }


    .srvlinks {
        height: 70vh;
        padding-bottom: 10vh;
        width: 85vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 0;
        justify-items: center;
        align-content: center;
    }
    .circlebtn {
        background-image: linear-gradient(to right, #00CCFF -10%, #224078 110%);
        height: 35px;
        width: 35px;
        border-radius: 200em;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(0deg);
        font-size: 30px;
    }
    .srvbtn {
        background-color: #272727;
        box-sizing: border-box;
        color: white;
        display: grid;
        grid-template-columns: 1.3fr 1.5fr 0.8fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        align-items: center;
        justify-items: center;
        height: calc(20px + 10vh);
        letter-spacing: 1px;
        padding: 2px;
        text-decoration: none;
        z-index: 2;
        font-family: tit-reg;
        font-size: calc(15px + 0.3vw);
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: 32px;
        text-align: center;
        margin-top: 3.5vh;
        width: 81vw;
        border: none;
        cursor: pointer;
        margin-left: -3.5vw;
    }

    .industriesServe {
        display: flex;
        flex-direction: column;
        height: calc(620px + 25vh);
        width: 90%;
        padding: 2%;
    }


    .industryList {
        list-style-type: style circle;
        padding-left: 5vw;
        text-align: left;
        font-family: tit-reg;
        font-size: calc(10px + 1.1vw);
        letter-spacing: 0.06em;
        line-height: 4.5vh;
        font-weight: normal;
    }

    .leftServe h3 {
        text-align: left;
        padding-top: 50px;
        width: 100%;
        margin-bottom: -35px;
    }


    .serveImg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 200px;
        width: 95%;
       
    }

    .serveTxt {
        width: 90%;
        text-align: left;
        font-family: tit-reg;
        font-size: 14px;
        font-style: italic;
        letter-spacing: 0.04em;
        line-height: 24px;
        font-weight: normal;
      
        margin-top: 15px;
        padding-bottom: 20px;
    }

    .rightServe {
        align-self: center;
        align-items: center;
        justify-items: center;
        justify-content: center;
        align-content: center;
    }

    .internalExternalCont {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
    }

    .externalScn {
  
        height: 45vh;
        width: 85%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: -7vw;
    }


    .internalScn {
        margin-top: 5%;
        height: 45vh;
        width: 85%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: -7vw;
    }

    .intImgDsc, .extImgDsc {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 5;
        background-color: #0f0f0fa3;
        width: 76.5%;
        height: 10vh;
        margin-top: 35vh;
        text-align: center;

        justify-content: center;
    }
 
 

    .inspectedObjects {
        display: flex;
        flex-direction: column;
        min-height: 120vh;
        width: 90%;
        padding: 3%;
        margin-top: 10vh;
        margin-bottom: 10vh;
    }

    .inspectedObjects h3 {

        width: 100%;
        margin-bottom: -35px;
        text-align: left;
    }

    .inspectedobj {
        background-image: url('/src/components/img/inspectedobjects.png');
        background-position: center;
        background-size: 135%;
        background-repeat: no-repeat;
        height: 50vh;
        width: 88vw;
        margin-left: -13vw;
        zoom: 1.2;
        margin-top: calc(-260px + -8vh);
        position: absolute;
    }

    .objTxt {
        width: 90%;
        text-align: center;
        font-family: tit-reg;
        font-size: 14px;
        font-style: italic;
        letter-spacing: 0.04em;
        line-height: 24px;
        font-weight: normal;
        padding-top: calc(90px + 4vh);
     
     
    }

    .servicesContainer{
        margin-bottom: -220px;
    }

    .servicesContainer h3 {
        text-align: left;
        padding-bottom: 4vh;
   
        margin-left: 3vw;
        line-height: 30px;
    }


    
}