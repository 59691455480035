.BoilerXContainer {
    width: 100vw;
    background-color: #1E1E1E;
}



.arrowSolutions {
    background-image: url('/src/components/img/arrow.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 1px;
    height: calc(9px + 0.4vw);
    width: calc(9px + 0.4vw);
}



.bsx1img {

    background-image: url('/src/components/img/bsx1img.png');}

.boilerXscanner {
    background-image: url('/src/components/img/scanner.png');

}
.leftSolution h1 {
    zoom: 1.05;
    margin-left: 0;
}

.leftSolution h3 {
    zoom: 0.9;
}



.solutionBSXImg1 {
    background-image: url('/src/components/img/wallscn2.gif');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.solutionBSXImg2 {
    background-image: url('/src/components/img/tildeilitenboiler.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.solutionBSXImg3 {
    background-image: url('/src/components/img/boilerscanx.jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.solutionBSXImg4 {
    background-image: url('/src/components/img/roofscn.gif');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}


.historySolution a {
    font-family: tit-reg;
}

.historySolution a:hover {
    font-weight: bold;
}

.scrollbkg {
    width: calc(0.07vw + 0.8px);
    height: 40vh;
    position: absolute;
    margin-top: 0vh;
    background: radial-gradient(50% 50% at 50% 50%, #F9F7F7 68.23%, rgba(249, 247, 247, 0) 100%);
    align-self: center;
    margin-left: 18.2vw;
}



.leftBoilerX {
    margin-top: -5vh;
}









.scaffimg1 {
    background-image: url('/src/components/img/fromlift.png');

}

.scaffimg2 {
    background-image: url('/src/components/img/smlopening.png');

}

.scaffimg3 {
    background-image: url('/src/components/img/wire.png');

}

.scaffimg1,
.scaffimg2,
.scaffimg3 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 38vh;
    margin-top: -1vh;

}

.scafftxt {
    background-color: #000000ac;
    height: 10vh;
    margin-top: -10vh;
    display: block;
    justify-items: center;
    align-content: center;

}

.bsx1bottom {
    grid-area: 3 / 1 / 4 / 3;
}





.setupimg1 {
    background-image: url('/src/components/img/2chsetup.png');
}

.setupimg2 {
    background-image: url('/src/components/img/3chsetup.png');
}

.setupimg3 {
    background-image: url('/src/components/img/1chsetup.png');
}

.setupimg4 {
    background-image: url('/src/components/img/3x1chsetup.png');
}





.limitimg1, .limitimg2, .limitimg3, .limitimg4, .limitdsc{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100px + 15vh);
    width: 15vw;
}

.limitimg1{
    background-image: url('/src/components/img/lugs.png');
}

.limitimg2{
    background-image: url('/src/components/img/cleaning.png');
}

.limitimg3{
    background-image: url('/src/components/img/weldcap.png');
}

.limitimg4{
    background-image: url('/src/components/img/bends.png');
}

.limitalign h5{
    line-height: 18px;
}

@media only screen and (min-width: 1000px) {
    .rightSolution {
        display: grid;
        grid-template-columns: 1.3fr 1fr;
        grid-template-rows: 1.3fr 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        height: 80vh;
    }

    .leftSolution {
        padding-left: 10vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 5fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        width: 25vw;
        height: 80vh;
        text-align: left;
        align-items: center;
    }


.historySolution {
    display: flex;
    justify-content: left;
    padding-top: 3vh;
    font-size: calc(10px + 0.3vw);
    align-items: center;
}
.boilerXscanner {
    height: 94vh;
    width: calc(55px + 9.5vw);
    position: absolute;
    z-index: 5;
    top: -4vh;
    align-self: center;
    left: 47vw;
    justify-self: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.solutionsHeading {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-top: 10vh;
    height: 80vh;
    background-color: #151515;
}

.bsxSection1 {
    background-image: url('/src/components/img/linesbkg2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 165vh;
    width: 80vw;
    text-align: left;

    margin-left: 10vw;
    margin-right: 10vw;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: -5vh;
    padding-top: 2vh;


}

.bsx1txt {
    width: 30vw;
    grid-area: 1 / 1 / 2 / 2;

    align-self: center;


}

.bsx1txt h5 {
    line-height: calc(20px + 2vh);
}

.bsx1img {


    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40vw;
    height: 60vh;
    grid-area: 1 / 2 / 2 / 3;
    margin-left: -6vw;

}


.bsx1scaff {
    grid-area: 2 / 1 / 3 / 3;
    background-color: #151515;
    height: 61vh;
    width: 90vw;
    margin-left: -10vw;


    margin-top: 5vh;
    display: flex;
    flex-direction: column;

    padding-left: 10vw;


}

.bsx1scaff h3 {
    text-align: center;
    margin-left: -10vw;
    margin-top: 4vh;
    margin-bottom: 0.5vh;

}
.scaffsections {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 80vw;
    text-align: center;
}
.scaffsection {
    width: 20vw;
}

.bsxSection2 {
    height: fit-content;
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-top: calc(-40px + -20vh);
    padding-right: 10vw;
    align-items: center;
    padding-bottom: 5vh;
}

.bsxSection2 h3 {
    padding-bottom: 1vh;
    width: 30vw;
    text-align: center;
}


.setups {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.setup {
    height: 33vh;
    width: 50vw;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.setupleft,
.setupright {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.setupleft ul {
    text-align: right;
    margin-right: 1.5vw;
}

.setupright ul {
    text-align: left;
    margin-left: -1vw;
}

.setupimg1,
.setupimg2,
.setupimg3,
.setupimg4 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 8vw;
}

.setuplist {
    list-style-type: none;
    font-family: tit-reg;
    font-size: calc(7px + 0.6vw);
    letter-spacing: 0.06em;
    line-height: calc(20px + 2vh);
    font-weight: normal;
    width: 70%;
}

.setuplist li {
    padding-bottom: 10px;
}
.bsxSection3{
    display: flex;
    width: 100vw;
    background-color: #151515;
    padding-left: 10vw;
   text-align: left;
   height: fit-content;
   padding-bottom: 5vh;
   padding-top: 3vh;
    
}



.limittxt{
    width: 35vw;
    padding-right: 5vw;

}

.limittxt h5{
    line-height: calc(20px + 2vh);
    width: 30vw;
}

.limitimgs h4{
    width: 76%;
}

.limitimgs{
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.limitalign{
    display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 10px;
grid-row-gap: 10px;
}


.limitdsc{
    text-align: center;
    align-content: center;
    justify-content: center;
    background-color: #0000008d;
    height: 5.5vh;
    margin-top: calc(100px + 9.5vh);
}



}

@media only screen and (max-width: 1000px) {
    .rightSolution {
        display: none;
    }

    .leftSolution {
        padding-left: 10vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 5fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        width: 60vw;
        height: 60vh;
        text-align: left;
        align-items: center;
    }


.historySolution {
    display: flex;
    justify-content: left;
    padding-top: 1vh;
    font-size: calc(10px + 1vw);
    align-items: center;
}

.boilerXscanner {
    height: 75vh;
    width: 129px;
    position: absolute;
    z-index: 5;
    top: -1vh;
    align-self: center;
    right: 0;
    justify-self: center;
    background-position: 6vw;
    background-repeat: no-repeat;
    background-size: 120%;
}

.solutionsHeading {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-top: 12vh;
    height: 63vh;
    background-color: #151515;
}

.leftBoilerX h3{
    padding-bottom: 1.5vh;
}

.bsxSection1 {
    background-image: url('/src/components/img/mobilelinesbkg.png');
    background-position: -5vw -1vh;
    background-repeat: no-repeat;
    background-size: 130%;
    height: fit-content;
    width: 100vw;
    text-align: left;
}

.bsx1txt {
    width: 85vw;
    padding-left: 10vw;
  
    height: calc(200px + 5vh);
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
}

.bsx1txt h5 {

}
.bsx1img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 80vw;
    margin-left: 10vw;
    margin-top: -50px;
    margin-bottom: -20px;
    height: 20vh;
}

.bsx1scaff {
    background-color: #151515;
    height: 160vh;
    width: 100vw;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bsx1scaff h3 {
    text-align: center;
    margin-top: 4vh;
    margin-bottom: 0.5vh;
    width: 90vw;


}

.scaffsections {
    display: flex;
    flex-direction: column;
  width: 100vw;
align-items: center;
    text-align: left;


}
.scaffsection {
    width: 80vw;
    margin-bottom: 3vh;
}

.bsxSection2 {
    height: fit-content;
    display: flex;
    flex-direction: column;
    width: 100vw;
  padding-top: 8vh;
    padding-right: 10vw;
    align-items: center;
    padding-bottom: 5vh;

}

.bsxSection2 h3 {
    padding-bottom: 1vh;
    width: 80vw;
    text-align: left;
}


.setups {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4vh;
}

.setup {
    height: fit-content;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: calc(10px + 5vh);
}

.setupleft,
.setupright {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80vw;
    height: 100%;

}



.setupleft ul {
    text-align: right;
    margin-right: 5vw;
}

.setupright ul {
    text-align: left;
    margin-left: -1.5vw;
}

.setupimg1,
.setupimg2,
.setupimg3,
.setupimg4 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 30vh;
    width: 30vw;
}

.setuplist {
    list-style-type: none;
    font-family: tit-reg;
    font-size: calc(11px + 1vw);
    letter-spacing: 0.06em;
    line-height: calc(20px + 2vh);
    font-weight: normal;
    width: 85%;
}

.setuplist li {
    padding-bottom: 10px;
}

.bsxSection3{
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: #151515;
    padding-left: 10vw;
   text-align: left;
   height: fit-content;
   padding-bottom: 5vh;
   padding-top: 4vh;
    
}


.limittxt{
    width: 80vw;
 

}

.limittxt h5{
    line-height: calc(20px + 1vh);
    width: 80vw;
}

.limitimgs h4{
    width: 80vw;
}

.limitimgs{
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.limitalign{
    display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-column-gap: 10px;
grid-row-gap: 10px;
}

.limitimg1, .limitimg2, .limitimg3, .limitimg4, .limitdsc{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100px + 15vh);
    width: 39vw;
}


.limitdsc{
    text-align: center;
    align-content: center;
    justify-content: center;
    background-color: #0000008d;
    height: 5.5vh;
    margin-top: calc(100px + 9.5vh);
}




}