@media only screen and (min-width: 1000px) {
    .quotecontainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: url("/src/components/img/quoteimg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100vw;
        height: 60vh;
        margin-bottom: 10vh;
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .quotetxt {
        display: block;
        padding: 5%;
        margin-left: 3vw;
        margin-right: 5%;
        font-style: italic;
        font-size: calc(15px + 1.8vw);
        line-height: calc(40px + 3vh);
        font-family: tit-bold;
        letter-spacing: 0.07em;
        background: -webkit-linear-gradient(left, #F9F7F7, #ffffff9c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: -8vh;
        text-align: center;
        width: 70vw;
    }

    .available {
        display: flex;
        text-align: center;
        align-self: center;
        font-size: calc(15px + 0.5vw);
        line-height: 7vh;
        font-family: tit-reg;
        letter-spacing: 0.05em;
        margin-top: -2.5vw;
        padding-bottom: 0px;
    }

    .oo {
        display: flex;
        align-self: center;
        zoom: 130%;
        padding-bottom: 2vh;
    }
}

@media only screen and (max-width: 1000px) {

    .quotecontainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: url("/src/components/img/mobilequote.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100vw;
        height: 70vh;
    
        align-items: center;
        margin-top: -10px;
        margin-bottom: 20px;
    }

    .available {
        display: flex;
        text-align: center;
        align-self: center;
        font-size: calc(15px + 0.5vw);
        line-height: 7vh;
        font-family: tit-reg;
        letter-spacing: 0.05em;
        margin-top: -2.5vw;
        padding-bottom: 0px;
    }

    .quotetxt {
        text-align: center;
        width: 80%;
        font-style: italic;
        font-size: calc(15px + 1.8vw);
        line-height: calc(10px + 3vh);
        font-family: tit-bold;
        letter-spacing: 0.07em;
        background: -webkit-linear-gradient(left, #F9F7F7, #ffffff9c);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-top: 3vh;
    }

    .oo {
        max-height: 25px;
    }
}