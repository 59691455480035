@media only screen and (min-width: 1000px) {

    .italictxt {
        font-style: italic;
        width: 45%;
    }

}

@media only screen and (max-width: 1000px) {
    .italictxt {
        font-style: italic;
        width: 90%;
        align-self: center;
        margin-top: 50px;
    }

}

.contentclientlogin a {
    height: 9vh;
    width: 17vw;
    align-self: center;
    margin-top: 0vh;

}

.contentclientlogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    width: 80%;
    justify-content: center;
    padding-top: 5vh;
    height: 95vh;
}

.redlink {
    color: #F7AD0A;
    border-bottom: 1px solid #F7AD0A;
}

.redlink:hover {
    color: #F55536;
    border-bottom: 1px solid #F55536;
}

.clientbkg {
    background-image: url(/src/components/img/background2.jpg);
    opacity: 0.4;
    min-height: 100vh;

}