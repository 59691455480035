.infoimg1{
  background-image: url(/src/components/img/nickeomange.jpeg);
  background-position: center;
  background-size: cover;
}

.infoimg2{
  background-image: url(/src/components/img/roofscn.gif);
  background-position: center;
  background-size: cover;
}

.infoimg3{
  background-image: url(/src/components/img/bigboilerscan.jpeg);
  background-position: center;
  background-size: cover;
}

.infostar{
  background-image: url(/src/components/img/Star.png);
  background-position: center;
  background-size: cover;
}

.spanblue {
  background: -webkit-linear-gradient(left, #00CCFF, #224078 180%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}


@media only screen and (min-width: 1000px) {
  .infoContainer {
    padding-top: 10vh;
    display: flex;
    width: 100vw;
    margin-left: -5%;
  }

  .infoContainer h3 {
    font-family: tit-reg;
    font-style: italic;
    font-size: calc(10px + 0.4vw);
    position: absolute;

    padding-bottom: 10px;
  
    transform: rotate(90deg);
    width: 300px;
    text-align: left;

    margin-left: calc(-95px + 8vw);
   
    margin-top: -36vh;
    position: inherit;
    z-index: -1;
  }

  .txtBorder {
    position: absolute;
   margin-top: calc(20px - 0.5vw);
    height: 2px;
    width: calc(350px + 75vh);
    background: linear-gradient(135deg, #F9F7F7 1.51%, rgba(249, 247, 247, 0.00) 101.37%);
  }

  .infobar {
    padding-top: calc(10px + 2vh);
    width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    align-items: center;
  }

  .infoImages {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 0.5fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    width: calc(80px + 35vw);
    height: calc(300px + 36vh);
    padding-left: calc(5px + 6vw);
    box-sizing: border-box;
    align-self: center;
    justify-self: end;
  }

  .infoimg1 {
    grid-area: 1 / 1 / 2 / 2;
    margin-bottom: 20px;
    box-sizing: border-box;
  }

  .infoimg2 {
    grid-area: 2 / 1 / 3 / 2;
    box-sizing: border-box;
  }

  .infoimg3 {
    grid-area: 1 / 2 / 3 / 3;
    right: 0;
    box-sizing: border-box;
  }

  .infoTxt {
    width: calc(180px + 20vw);
    height: calc(500px + 50vh);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-left: 15vw;
  }

  .infoTxt h2 {
    margin-bottom: 1.5vh;
  }

  .icon {
    max-height: calc(14px + 1.5vh);
    margin-top: 7px;
  }

  .infostar {
    width: calc(33px + 1.5vw);
    height: calc(28px + 1.5vw);
    margin-bottom: 9vh;
    margin-top: -0.8vh;
    margin-left: 7.5vw;
    z-index: 5;
    padding: 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .infostars {
    position: absolute;
    padding-top: 18vh;
  }

  .infoBox {
    margin-bottom: 2vh;
  }

 
}

@media only screen and (max-width: 1000px) {
  .infoContainer {
    min-height: fit-content;
    margin-left: 10%;
    margin-right: 10%;
  }

  .infoContainer h3 {
    font-family: tit-reg;
    font-style: italic;
    font-size: calc(10px + 0.9vw);
    padding-bottom: 10px;
   height: 3vh;
   display: flex;
   text-align: left;
   flex-direction: column-reverse;
   justify-content:space-between;
    transform: rotate(90deg);
    margin-left: calc(-98px + 8vw);
    width: 200px;
    margin-top: -22vh;
    position: inherit;
    z-index: -1;
  }

  .txtBorder {
    position: absolute;
    height: 2px;
    width: calc(190px + 70vh);
    background: linear-gradient(135deg, #F9F7F7 1.51%, rgba(249, 247, 247, 0.00) 101.37%);

  }

  .icon {
    height: 3vh;
    width: 3vh;
    margin-top: 0.5vh;
  }

  .infostar {
    width: calc(33px + 2vw);
    height: calc(30px + 2vw);
    margin-bottom: 9vh;
    margin-top: -0.8vh;
    z-index: 5;
    padding: 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .infostars {
    position: absolute;
    padding-top: 10vh;
    margin-left: -17vw;
    z-index: 5;
  }

  .infoTxt {
    width: calc(180px + 20vw);
    height: calc(300px + 30vh);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3vw;
  }

  .infoTxt h2 {
    margin-bottom: 4vh;
  }
  .infoBox {
    margin-bottom: 4vh;
  }
}