.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

}

.subhead {
    margin: 5px;
    letter-spacing: 1px;
}



.btn {
    background-image: linear-gradient(to right, #00CCFF -10%, #224078 110%);
    box-shadow: 7px 8px 3px rgb(46, 46, 46);
    box-sizing: border-box;
    color: white;
    display: block;
    height: calc(40px + 1.5vh);
    letter-spacing: 1px;
    padding: 2px;
    position: relative;
    text-decoration: none;
    z-index: 2;
    font-family: tit-reg;
    font-size: calc(15px + 0.2vw);
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 32px;
    text-align: center;
    margin-top: 8vh;
    width: calc(160px + 0.6vw);
    border: none;
    cursor: pointer;
}

.btn:hover {
    color: #fff;
}

.btn span {
    align-items: center;
    background: #282828;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    height: 100%;
    transition: 0.5s ease;
    width: 100%;
}

.btn:hover span {
    background: transparent;
}

#servicesscroll {
    align-self: center;
    margin-top: -1vh;
    margin-bottom: 1vh;
}
/* 

.instaSection {
    margin-bottom: 10vh;
    padding: 5vh;
}

.instaSection h2 {
    margin-bottom: 5px;
}

.lightwidget-widget {
    background: linear-gradient(#00CCFF, #224078);
    padding: 2em
}

.LI-profile-badge {
    max-height: 50px;
    max-width: 50px;
}
 */

 .instaSection{
    display: none;

 }

.boilerservice {
    display: flex;
    flex-direction: row;
    height: 70vh;
    align-items: center;
    margin-bottom: 15vh;
}

.verticalline {
    transform: rotate(90deg);
    justify-self: center;
    margin-top: 10vh;
    height: 2px;
    width: calc(130px + 50vh);
    background: linear-gradient(135deg, #F9F7F7 1.51%, rgba(249, 247, 247, 0.00) 101.37%);
}



.clientdiv{
    background-image: url('/src/components/img/whattoexpect.png');

}

.pp {
    margin-right: -7vw;
}

.fi {
    margin-left: -7vw;
}

.tophead {
    margin-left: 0.5%;
    font-size: calc(20px + 0.5vw);
    margin-bottom: 3px;
    letter-spacing: 2.5px;
    font-family: tit-bold;
}



@media only screen and (min-width: 1000px) {

    .bkgimage {
        background-image: url(/src/components/img/bluebkg2.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100vh;
        width: 100vw;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
    }

    .boilerserviceimg {
        height: calc(150px + 2.4vh);
        width: calc(160px + 1.25vw);
        position: absolute;
        left: calc(-75px + 49vw);
    }

    .whyus {
        width: 100%;
        z-index: 2;
        margin-top: calc(-70px - 10vh);
        height: calc(530px + 48vh);
    }

    .btn {
        justify-self: flex-end;
        align-self: flex-end;
    }

    .imgcontent {
        min-height: 100vh;
        min-width: 100%;
        padding-left: 10%;
        display: flex;
        flex-direction: column;
        align-content: left;
        justify-content: center;
        text-align: left;
        z-index: 8;
        position: relative;
    }

    .boilerserviceimg {
        background-image: url('/src/components/img/boilerservice.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .pipe {
        position: absolute;
        z-index: 4;
        right: calc(36px + 3.5vw);
        height: calc(700px + 510vh);
        width: calc(13px + 0.7vw);
        background-image: url('/src/components/img/pipe.png');
        background-position: center;
        background-size: contain;
    }

    .scanner {
        height: 75vh;
        width: calc(65px + 6vw);
        position: fixed;
        z-index: 5;
        right: -0.65vw;
        top: 9vh;
        background-image: url('/src/components/img/scanner.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        animation: bouncescanner 3s infinite;
    }

    @keyframes bouncescanner {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }

        40% {
            transform: translateY(-30px);
        }

        60% {
            transform: translateY(-10px);
        }
    }

    .subhead {
        margin-bottom: 60px;
        font-size: calc(10px + 0.8vw);
        min-width: calc(600px + 6vw);
        letter-spacing: 0.06em;
    }

    .clientdiv {
        display: flex;
        align-items: center;
        text-align: left;
        margin-top: 10vh;
        height: 55vh;
        width: 100vw;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

  
    }

    .insideClient {
        width: 50%;
        margin-left: 10vw;
        text-align: left;
    }
}

@media only screen and (max-width: 1000px) {

    .imgcontent {
        min-height: 95vh;
        width: 95%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: left;
        z-index: 8;
      
    }

    .imgcontent h1{
        margin-left: 2vw;
    }
   
  
    .bkgimage {
        background-image: url('/src/components/img/homemobilebkg.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100vh;
        width: 100vw;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
    }

    .tophead{
        font-size: calc(14px + 1vw);
        margin-left: 2vw;
    }

    .subhead {
        min-width: 95%;
        margin: 0;
        align-self: center;
        font-size: calc(12px + 1.1vw);
        line-height: 22px;
    }

    .btn {
        justify-self: center;
        align-self: center;
    }

    .clientdiv {
        display: flex;
        align-items: center;
        height: fit-content;
        width: 100vw;
        padding-bottom: 10vh;
        padding-top: 10vh;
       
        z-index: 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .insideClient{
        max-width: 80%;
        padding-left: 9vw;
        text-align: left;
    }

    .insideClient h3{
        width: 60%;
        line-height: normal;
        margin-bottom: 3vh;
    }

    .insideClient h5{
        margin-bottom: -2vh;
    }

    .whyus {
        padding-bottom: 30px;
        height: 70vh;
    }

    .weinspect{
        margin-top: 5vh;
        margin-bottom: 2vh;
        width: 80vw;
      
    }

   

    .verticalline{
        display: none;
    }

    .boilerservice{
        display: flex;
        flex-direction: column;
        padding-top: 10vh;
        height: 55vh;
        
    }
    
    .boilerserviceimg {
        background-image: url('/src/components/img/mobileboilerservice.png');
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        height: 55vh;
        width: 32vw;
        position: absolute;
        left: 5%;
    }

    .fi{
       margin-top: 20vh;
 
    }

    .pp{
        margin-top: 10vh;
    }

    .pp, .fi{
        text-align: left;
        margin-left: 10vw;
    }

    /*
    .lightwidget-widget{
        margin-top: 2vh;
        min-width: 401px;

        padding: 20px;
        display: block;
        align-items: center;
        justify-content: center;
       
    }

  */

}