.contacticons{
  background-image: url(/src/components/img/contacticons.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

}

.follow h4{
  margin-bottom: 1.5vh;
}

@media only screen and (min-width: 1000px) {

  .contacticons{
    background-image: url(/src/components/img/contacticons.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  
    height: 52vh;
    width: 3vw;
    position: absolute;
  
  }

  .splitBkg {
    min-height: 100vh;
    min-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 0px;
    align-items: center;
    text-align: left;
  }

  .contactInfo {
    padding-top: 10vh;
    margin-left: 20%;
  }
  .contactinformation{
    height: 50vh;
    padding-left: 5vw;
  }

  .subcontact{
    margin-top: 1vh;
    margin-bottom: 2vh;
  }

  .contactbox {
    display: flex;
    flex-direction: row;
    align-items: center;
 
  }

  .contactbox:hover{
    background: -webkit-linear-gradient(left, #00CCFF, #224078 180%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }



  .contactbox h5 {
    margin-top: -1vh;
    padding-bottom: 1vh;
    margin-left: -0.3vw;
    font-weight: bold;
  }


  .address h5{
    margin-top: -2vh;

  }


  .ya{
    margin-top: -1vh;
  }

  .map {
    background-image: url(/src/components/img/contactbkg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 10vh;
    height: 90vh;
    width: 50vw;
    align-content: center;
    justify-content: center;
  }

  .mappi {
    margin-top: 5%;
    margin-left: 10%;
    opacity: 0.9;
  }
}

@media only screen and (max-width: 1000px) {
 .contactInfo{

  text-align: left;
  padding-left: 10vw;
  padding-top: 15vh;

 }
 .subcontact{
  margin-left: 4px;
  margin-right: 20px;
}

 .contacticons{
  height: 660px;
  width: 46px;
  position: absolute;
  top: 17vh;
}

.contactinformation{
  margin-top: 4vh;
  margin-left: 60px;
}

.address h5{
  margin-top: -10px;
}

.contactbox h5 {
  margin-top: 18px;
  padding-bottom: -10px;
  margin-left: -0.3vw;
  font-weight: bold;
}

.ya{
  padding-top: 15px;
  padding-bottom: 15px;
}

.map {
  background-image: url(/src/components/img/contactbkg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10vh;
  height: 80vh;
  width: 100vw;
  align-content: center;
  justify-content: center;
}

.mappi {
  margin-top: 2%;
  margin-left: 15%;
  opacity: 0.9;


}




}