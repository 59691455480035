.carousel-container {
  position: relative;
  width: 40vw;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: calc(10px + 5vh);
}

.carousel-wrapper {
  position: relative;
}

.carousel-image {
  width: 40vw;
  height: 50vh;
  object-fit: cover;
}

.carousel-image-preview {
  position: absolute;
  top: 0vh;
  opacity: 0.13;
  height: 50vh;
  width: 17.5vw;
  object-fit: cover;
}

.carousel-image-preview.left {
  left: calc(50% - 40vw);
  /* Placera till vänster om aktuell bild med 30px avstånd */
}

.carousel-image-preview.right {
  right: calc(50% - 40vw);
  /* Placera till höger om aktuell bild med 30px avstånd */
}

.carousel-description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-family: tit-reg;
  font-size: calc(14px + 0.4vw);
  letter-spacing: 0.05em;
  line-height: normal;
  font-weight: bold;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding-bottom: 20px;
  padding-top: 20px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: calc(20px + 1.5vw);
  cursor: pointer;
  color: aliceblue;
  padding: 20px;
}

.carousel-button.previous {
  left: -100px;
  background-image: url("/src/components/img/arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 2.5vh;
  transform: rotate(180deg);
  margin-top: -2.5vh;
}

.carousel-button.next {
  right: -100px;
  background-image: url("/src/components/img/arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 2.5vh;
  top: 50%;
}

.carousel-button.carousel-button.previous:hover, .carousel-button.next:hover{
  background-image: url("/src/components/img/arrow-hover.png");
}

@media only screen and (max-width: 1000px) {

  .carousel-image {
    width: 70vw;
    height: 50vh;
    object-fit: cover;
  }

  .carousel-image-preview {
    display: none;
  }


  .carousel-container {
    padding: 30px;
    padding-bottom: 20px;
  }

  .carousel-button.previous {
    left: -65px;
  }
  
  .carousel-button.next {
    right: -65px;
  }


  


}
