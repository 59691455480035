.scroll-list {
  width: 80%;
  height: 25vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
  margin-top: 22.5vh;
  padding-top: 5vh;
  padding-left: 10vw;
  padding-bottom: 5vh;
  font-family: tit-reg;
  font-size: calc(9px + 0.5vw);
  align-self: center;
  margin-left: 10vw;
}

.list-item {
  padding: 6px;
  cursor: pointer;
  opacity: 0.4;
  transition: ease-in 0.3s;
}

.list-item.active {
  opacity: 1;
}

.list-item img {
  width: calc(15px + 0.9vw);
  height: calc(15px + 0.9vw);
  margin-left: calc(-2.2vw - 14px);
  top: -50px;
  position: relative;
  opacity: 1;
}

.description {
  margin-top: calc(-40px + 0.3vw);
  display: none;
}

.list-item h3 {
margin-top: -0.5vw;
  font-size: calc(9px + 0.5vw);
}