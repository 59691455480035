@font-face {
  font-family: "bebas";
  src: local("bebas"), url("./fonts/BebasNeue.otf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "tit-light";
  src: local("tit-light"), url("./fonts/TitilliumWeb-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "tit-reg";
  src: local("tit-reg"), url("./fonts/TitilliumWeb-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "tit-bold";
  src: local("tit-bold"), url("./fonts/TitilliumWeb-Bold.ttf") format("truetype");
  font-weight: normal;
}

.App {
  text-align: center;
  background: linear-gradient(#151515, #141414);
  background-attachment: fixed;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fbfbfb;
  padding: 0px 5% 0px 5%;
  overflow: hidden;
}


@media only screen and (min-width: 1000px) {
  h1 {
    font-family: bebas;
    font-size: calc(30px + 2.3vw);
    letter-spacing: 0.06em;
    margin: 5px;
    font-weight: 400;

  }

  h2 {
    font-family: tit-bold;
    font-size: calc(15px + 0.9vw);
    letter-spacing: 0.06em;
    font-weight: lighter;
    padding-bottom: 2vh;
  }

  h3 {
    font-family: tit-bold;
    font-size: calc(17px + 0.5vw);
    letter-spacing: 0.06em;
    font-weight: lighter;
    line-height: 40px;
  }

  h4 {
    font-family: tit-bold;
    font-size: calc(10px + 0.6vw);
    letter-spacing: 0.06em;
    line-height: normal;

  }

  h5 {
    font-family: tit-reg;
    font-size: calc(8px + 0.6vw);
    letter-spacing: 0.06em;
    line-height: normal;
    font-weight: 300;
  }
}

@media only screen and (max-width: 1000px) {
  h1 {
    font-family: bebas;
    font-size: calc(33px + 1.3vw);
    letter-spacing: 0.04em;
    margin: 5px;
    font-weight: normal;
  }

  h2 {
    font-family: tit-bold;
    font-size: calc(16px + 1vw);
    letter-spacing: 0.03em;
    font-weight: lighter;
    margin: 1px;
  }

  h3 {
    font-family: tit-bold;
    font-size: calc(15px + 1vw);
    letter-spacing: 0.03em;
    font-weight: lighter;
    line-height: 30px;
    margin: 1px;
  }

  h4 {
    font-family: tit-bold;
    font-size: calc(12px + 1vw);
    letter-spacing: 0.04em;
    line-height: normal;

  }

  h5 {
    font-family: tit-reg;
    font-size: calc(10px + 1.1vw);
    letter-spacing: 0.03em;
    line-height: 24px;
    font-weight: normal;
    margin: 1px;
  }
}