.irisscanner{
    background-image: url('/src/components/img/irisprobe.png');

}

.irisinlife{
    background-image: url('/src/components/img/iris.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.irisbkg{
    background-image: url('/src/components/img/irisbkg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.irisScan{
    background-image: url('/src/components/img/iriscscan.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}



@media only screen and (min-width: 1000px) {

    .irisscanner{
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100vh;
        width: calc(65px + 20vw);
        position: absolute;
        z-index: 5;
        top:2vh;
        align-self: center;
        left: 60vw;
        justify-self: center;
        transform: rotate(-30deg);
    }

    .irisinlife{
        width: 25vw;
        height: 60vh;
    }

    .irisbkg{
        margin-top: 10vh;
    }
    .iristxt{
        width: 50%;
    }

    .irisScan{
        height: 40vh;
        width: 40vw;
    }

}

@media only screen and (max-width: 1000px) {

    .irisscanner{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 10vh;
        width: 60vw;
        position: absolute;
        z-index: 5;
        top: 40vh;
        align-self: center;
        left: 50vw;
        justify-self: center;
        transform: rotate(90deg);
    }

    .irisinlife{
        width: 80vw;
        height: 60vh;
        margin-left: 10vw;
        margin-top: -15vh;
        margin-bottom: -15vh;
    }

    .irisbkg{
        height: 60vh;
    }
    .iristxt{
        width: 80vw;
    
    }

    .irisScan{
        height: 40vh;
        width: 80vw;
        margin-bottom: 5vh;
    }


    
}
