.solutionPAImg1 {
  background-image: url('/src/components/img/PHASEDARRAYIMAGES.png');
  background-position: center;

  background-repeat: no-repeat;
}

.pAcontainer {
  display: flex;
  flex-direction: column;
  margin-left: 18vw;
  width: 82vw;
  margin-top: 10vh;
  text-align: left;
}

.leftLanding {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.menuItem {
  padding: 1px;
  padding-left: 25px;
  margin-top: 4vh;
  margin-bottom: 4vh;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Gör övergången mjuk när man hovrar över menyposterna */
  font-family: tit-reg;
  font-size: calc(13px + 0.4vw);
  color: #ffffff;

  border-left: 2px solid;
  border-image:
    linear-gradient(to bottom,
      #ffffff2d,
      #ffffff,
      rgba(255, 255, 255, 0.102)) 1 100%;
}

.menuItem:hover,
.menuItem.active {
  font-family: tit-bold;
  color: #00CCFF;
  border-left: 3px solid;
  border-image:
    linear-gradient(to bottom,
      #ffffff2d,
      #00CCFF,
      rgba(255, 255, 255, 0.102)) 1 100%;
}

.pA1img {
  background-image: url('/src/components/img/bendscan.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.pA2img {
  align-self: center;
  background-image: url('/src/components/img/buttwelds.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#pAsection3 {
  padding-bottom: 15vh;
}

/* Styla rubriker och text i sektionerna efter behov */
.pAsection h2 {
  font-size: calc(18px + 0.4vw);
  margin-bottom: 10px;
}


@media only screen and (min-width: 1000px) {

  .pAmenu {
    width: 10vw;
    /* Bredden på menyn */
    height: 76vh;
    /* Höjden på menyn, anpassa efter behov */
    background-color: #1E1E1E;
    /* Bakgrundsfärg på menyn */
    position: fixed;
    z-index: 4;
    left: 0;
    top: 10vh;
    padding: 4vw;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
  }

  #pAhistory {
    position: absolute;
    top: 6vh;
  }



  .pAlanding {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 90vh;
    background-color: #151515;
    padding-left: 5vw;
    align-items: center;
  }

  .rightLanding {
    width: 30vw;
    height: 60vh;
  }

  .solutionPAImg1 {
    height: 100%;
    width: 30vw;
    background-size: cover;
  }

  .browse {
    position: absolute;
    bottom: 5vh;
    font-style: italic;
  }

  .pAcontent {
    flex: 1;
    /* Innehållet tar upp all tillgänglig plats bredvid menyn */
    overflow-y: auto;
    /* Skapa en scrollbar vid behov */
    padding: 5vw;
    /* Mellanrum mellan innehållet och kanten av fönstret */
  }

  .pAsection {
    padding-top: 30vh;
    margin-top: -20vh;
    padding-bottom: 10vh;

  }


  #pAsection1 {
    margin-top: -20vh;
    display: flex;
    flex-direction: row;

  }



  .pA1img {
    width: 30vw;
    height: 50vh;
    align-self: center;
    margin-top: 8vh;

  }

  .pA2align {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .pA2txt {
    width: 25vw;

  }

  #pAsection2 h4 {
    width: 30vw;
  }

  .pA2txt h5 {
    line-height: 35px;
    margin-bottom: -2vh;
  
  }

.pA2img {
  margin-top: -20vh;
  width: 50vw;
  height: 45vh;
  align-self: center;
}


}

@media only screen and (max-width: 1000px) {

  .pAmenu {
    padding-top: 3vh;
    margin-left: -10vw;
  }

  .pAmenu .menuItem {
    display: none;
  }


  .pAlanding {
    display: flex;
    flex-direction: column;

    height: 90vh;
    margin-left: -9vw;
    width: 84vw;
    background-color: #151515;
    text-align: center;
    padding-top: 5vh;
  }

  .rightLanding {
    width: 85vw;
    margin-top: 3vh;
    height: 30vh;
  }

  .solutionPAImg1 {
    background-size: contain;
    height: 90%;
    width: 85vw;
  }

  .browse {
    position: absolute;
    bottom: 2vh;
    font-style: italic;
    text-align: center;
    width: 80vw;
  }

  .pAsection {
    margin-top: -8vh;
    margin-left: -9vw;

  }


  .pA1txt {
    width: 85vw;
  }



  .pA1img {
    width: 55vw;
    height: 70vh;
    margin-top: -10vh;
  }

  .pA2align{
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .pA2txt{
    width: 85vw;

  }

#pAsection2 h4{
  width: 85vw;
}

#pAsection2{
  width: 85vw;
  height: fit-content;
  padding-bottom: 10vh;
}

.pA2txt h5 {
  line-height: 25px;
  margin-bottom: -2vh;

}

.pA2img {
  margin-top: 0vh;
  width: 75vw;
  height: 30vh;

}




}