.neardrumscanner {
    background-image: url('/src/components/img/neardrumscanner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

}


.rectangle {
    background-image: url('/src/components/img/bluerectangle.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}




.ndimg1 {
    background-image: url('/src/components/img/neardrum.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 25vw;
    margin-left: 5vw;

}



.software h3 {
    text-align: left;
    margin-left: 0;
    margin-top: 4vh;
    margin-bottom: 0.5vh;
    align-self: center;
    justify-self: center;
}

.softwimg {
    background-image: url('/src/components/img/softwarend.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}




.reporting {
    display: flex;
    flex-direction: column;
}

.reporting h4 {
    margin-bottom: 0;
}



.reportimg1 {
    background-image: url('/src/components/img/tubesheet.png');

}

.reportimg2 {
    background-image: url('/src/components/img/singletubereport.png');

}



@media only screen and (min-width: 1000px) {


    .rectangle {

        height: 70vh;
        width: 65vw;
        position: absolute;
        right: 0;
        top: calc(40px + 18vh);
    }

    .neardrumscanner {
        height: 100vh;
        width: calc(65px + 20vw);
        position: absolute;
        z-index: 5;
        top: 2vh;
        align-self: center;
        left: 60vw;
        justify-self: center;
        transform: rotate(60deg);
    }

    .neardrumadjust {
        width: 35vw;
        height: 20vh;
    }

    .neardrumscan {
        background-image: url('/src/components/img/background6.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 32vh;
        width: 30vw;
        align-self: flex-end;
        margin-top: 17vh;
    }

    .software {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20vh;
        height: 53vh;
    }

    .softwtxt {
        width: 30vw;
        text-align: left;
    }

.softwtxt h5 {
    line-height: calc(20px + 2vh);
}

.softwimg {
    width: 40vw;
    height: 35vh;
    margin-left: 13vw;
}

.report {
    height: 70vh;

}

.reportimg1,
.reportimg2 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40vw;
    height: 35vh;
    margin-bottom: 5vh;

}

}

@media only screen and (max-width: 1000px) {

    .rectangle {

        height: 60vh;
        width: 70vw;
        position: absolute;
        right: 0vw;
        top: 35vh;
        z-index: 0;

    }

    .neardrumscanner {
        height: 25vh;
        width: 30vw;
        position: absolute;
        z-index: 5;
        top: 30vh;
        align-self: center;
        left: 70vw;
        justify-self: center;


    }

    .neardrumadjust{
        padding-top: 5vh;
    }

    .neardrumscan {
        background-image: url('/src/components/img/background6.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 25vh;
        width: 80vw;
        margin-left: 10vw;
    }

    .software{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8vh;
        padding-top: 1vh;
        height: 60vh;

    }

    .softwtxt {
        width: 80vw;
        text-align: left;
  

    }


.softwtxt h5 {
    line-height: calc(20px + 1vh);


}

.softwimg {
    width: 80vw;
    height: 35vh;

    
}

.reportimg1,
.reportimg2 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 60vw;
    height: 30vh;
    margin-bottom: 5vh;
}

.reportimg2{
    margin-bottom: -5vh;
}

.reportingtext{

    width: 100vw;
    text-align: center;
    margin-left: 60vw;
}


}