.menuNavigation {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  z-index: 10;
  position: fixed;

}

a {
  text-decoration: none;
  color: white;
  padding: 2px;
}


a:active,
a:hover {
  text-decoration: none;
  /* border-bottom: 2px solid #F55536;*/
}






@media only screen and (min-width: 1000px) {

  .white {
    background-color: rgba(25, 25, 25, 0.5);
    backdrop-filter: blur(4px);
    transition: 0.5s ease;

  }

  .mobilemenu {
    display: none;
  }

  .menucontent {
    display: none;
  }


  /* Styles for the links within the dropdown content */
  .navlink {
    text-decoration: none;
    /* Remove default underline */
    display: block;
    min-width: 200px;
    font-family: tit-reg;
    font-size: calc(12px + 0.4vw);
  }

  .navlink.navlink1 {
    grid-area: 1 / 1 / 2 / 4;
    font-family: tit-bold;
  }

  .navlink2 {
    grid-area: 2 / 1 / 3 / 2;
  }

  .navlink3 {
    grid-area: 2 / 2 / 3 / 3;
  }

  .navlink4 {
    grid-area: 2 / 3 / 3 / 4;
  }

  .navlink5 {
    grid-area: 3 / 1 / 4 / 2;
  }

  .navlink6 {
    grid-area: 3 / 2 / 4 / 3;
  }

  .navlink7 {
    grid-area: 3 / 3 / 4 / 4;
  }

  .dropdown-services {
    background-color: white;
  }

  /* CSS for dropdown container */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* CSS for the Services link */
  .services-link {
    cursor: pointer;
    /* Change cursor to pointer to indicate clickable */
  }

  /* CSS for dropdown content */
  .dropdown-content {
    display: none;
    position: absolute;
    z-index: 0;
   top: 100% ;
    padding-left: 15vw;
    padding-right: 75vw;
    margin-left: calc(220px - 59vw);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    background-color: rgb(21, 21, 21);
    /* Background color */
    padding-top: 4vh;
   
  }

  /* Show the dropdown content when hovering over the dropdown container */
  .dropdown:hover .dropdown-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 20vh;
    padding-bottom: 5vh;
  }
  /* Links inside the dropdown */
  .dropdown-content a {
    color: white;
    /* Text color */
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    width: 200px;
    align-self: flex-start;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {
    background-color: #2f2f2f;
    /* Hover background color */
  }

  .arrowhead {
    background-image: url('/src/components/img/arrow.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 10px;
    margin-left: 5px;
    transform: rotate(0deg);
    height: calc(9px + 0.4vw);
    width: calc(9px + 0.4vw);
  }

  /* Rotate the arrow down when hovering */
  .dropdown:hover .arrowhead {
    transform: rotate(90deg);
  }

  .bm-overlay {
    display: none;
  }

  .hidemobile {
    display: none;
  }

  .menuNavigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: tit-reg;

    height: 10vh;

  }

  .menu {
    display: none;
  }

  .radialBorder {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    margin-left: -5vw;
    bottom: -2px;
    height: 1.9px;
    width: 110vw;
    background: radial-gradient(50% 50% at 50% 50%, #F9F7F7 68.23%, rgba(249, 247, 247, 0) 100%);
  }

  .menuNavigation .centeredLinks {
    order: 2;
    flex-grow: 1;
    text-align: center;
  }

  .menuNavigation .clientlink {
    order: 3;
    margin-left: auto;
  }

  .logo {

    max-width: calc(70px + 2vw);
    max-height: 7vh;
    display: flex;
    z-index: 10;
    margin-left: 30px;
  }

  .centeredLinks {

    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;


  }

  .btnclientlogin {

    width: 160px;
    height: 50px;
    justify-self: flex-end;
    align-self: flex-end;
    background-image: linear-gradient(to right, #00CCFF -10%, #224078 110%);
    box-sizing: border-box;
    color: white;
    display: block;

    letter-spacing: 1px;

    padding: 2px;
    position: relative;
    text-decoration: none;


    z-index: 2;
    font-family: tit-reg;
    font-size: calc(15px + 0.2vw);
    font-weight: bold;
    letter-spacing: 0.05em;
    line-height: 32px;

    text-align: center;
    border: none;
    cursor: pointer;

  }

  .btnclientlogin span {
    align-items: center;
    background: #282828;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    height: 100%;
    transition: 0.5s ease;
    width: 100%;


  }

  .btnclientlogin:hover span {
    background: transparent;
  }





  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    width: 100%;

  }


  .link {
    list-style-type: none;
    float: right;
    margin-left: 60px;
    background-color: transparent;
    font-family: tit-reg;
    font-size: calc(14px + 0.4vw);
    ;
    border: none;
    color: #FBFBFB;
    margin-top: 40px;
    height: 35px;
    padding: 5px;

    display: flex;

  }




  .active-link {
    font-weight: bold;
    font-family: tit-bold;

    color: #00CCFF;
  }



  .link:hover {

    font-weight: bolder;
    font-family: tit-bold;
  }
}

@media only screen and (max-width: 1000px) {
  .logo {
    width: 90px;
    height: 11vh;
    z-index: 10;
    padding-left: 3vw;
    position: fixed;

  }

  .navigation {
    display: none;
  }

  .menuicon {
    padding: 10px;
    align-self: center;
    position: fixed;
    z-index: 15;
    right: 5vw;
    width: 70px;
    margin-top: 30px;
  }

  .menuNavigation {
    height: 11vh;
    justify-content: left;
    padding-left: 10vw;
    position: fixed;
    background-color: rgba(20, 20, 20, 0.5);
    backdrop-filter: blur(4px);
  
  }


  .navlink,
  .bm-item {

    width: fit-content;
    padding: 0.2em 0em 0.1em 0em;
    margin: 0.5em 1em 0.5em 1em;
    align-items: left;
    text-align: left;

    width: 100%;
  }

  .navlink {
    font-family: tit-reg;
    font-size: 18px;
    margin-bottom: 0.3em;
  }

  .dropdown-content {
    display: flex;
    flex-direction: column;
  }

  .show {
    display: block;
  }


  .mobilemenu {
    width: 100%;
    position: absolute;

    left: 0;
    background-color: rgb(36, 36, 36);

    font-family: tit-bold;
    font-size: 20px;
    height: 100vh;
  }

#react-burger-menu-btn, .bm-burger-button{
    width: 130px;
    height: 12vh;
    position: fixed;
    top: 0vh;
    z-index: 10;
    right: 0px;
 
  }

  .bm-item-list {
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
  }


  .bm-item {
    padding-left: 0.8em;
    text-align: left;
    margin-top: 0.1em;
  }

  .bm-overlay {
    right: 0;
  }

  .firstmobileitem {
    margin-top: 3em;
  }

  .bm-menu-wrap.mobilemenu {
    max-width: 75%;
  }

  .bm-cross-button,
  #react-burger-cross-btn {
    z-index: 100;
    background: url('./kryss.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-height: 17px;
    max-width: 17px;
    margin-top: 10px;
    margin-right: 10px;
  }



  .active-link {
    font-weight: bold;
    font-family: tit-bold;

    color: #00CCFF;
  }






}