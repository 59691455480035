
@media only screen and (min-width: 1000px) {
.footercontainer{
    background:  rgb(20, 20, 20) ;
    height: 20vh;
    width: 100vw;
 
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.bluestripe{
    background-image: linear-gradient(to right, #00CCFF, #224078 100%);
    width: 100vw;
    height: 1vh;
    position: absolute;
    
}

.footersection{
    width: 23vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.footersection h5{
    margin: -10px;
    font-size: calc(8px + 0.5vw);
}

.media{
  width: 45px;
margin-top: -8px;
  display: flex;
  justify-content: space-between;
  
}

.logoo{
    margin-bottom: -10px;
    margin-top: -10px;
}}

@media only screen and (max-width: 1000px) {
    .footercontainer{
        background:  rgb(20, 20, 20) ;
        height: 43vh;
        width: 111%;
     
    }
    .bluestripe{
        background-image: linear-gradient(to right, #00CCFF, #224078 100%);
        width: 100vw;
        height: 1vh;
        position: absolute;
        
    }

    .footercontainer h2{
        margin: 0;
    }

    .footersection1, .footersection3{
        position: absolute;
       
        left: 0;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 20px;
    }

    .footersection1, .footersection2{
        margin-top: 40px;
    }

    .footersection3, .footersection4{
        margin-top: 200px;    
    }

    .footersection2, .footersection4{
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        text-align: right;
        margin-right: 20px;
        max-width: 45vw;
    }
    .logoo{
      
        height: 25px;
    }
    .media{
        width: 45px;
        display: flex;
        justify-content: space-between;
        padding-left: 25px;
        height: 20px;
        align-items: flex-end;
        
      }

}