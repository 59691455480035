.aboutContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    width: 100vw;
    height: 100%;

}

.imgAbout {
    background-image: url("/src/components/img/industryview.jpg");
}


.scrollBottom {
    margin: auto;
    margin-top: -18px;
    height: 2px;
    width: calc(140px + 8vw);
    background: radial-gradient(50% 50% at 50% 50%, #F9F7F7 68.23%, rgba(249, 247, 247, 0) 100%);
}

.scrollDown {
    position: absolute;
    z-index: 10;
    top: 91vh;
    align-items: center;
    justify-items: center;
    text-align: center;
    opacity: 1;
    transition: opacity 0.4s ease;
}

.vanish {
    opacity: 0;
}

.scrollDown h5 {
    font-size: calc(11px + 0.5vw);
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-25px);
    }

    60% {
        transform: translateY(-10px);
    }
}







.ceoImg {
    background-image: url("/src/components/img/nickeotilde.jpeg");
}

.imgCollage {
    background-image: url("/src/components/img/aboutslinga2.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

@media only screen and (min-width: 1000px) {

    .imgAbout {
        height: 90vh;
        margin-top: 10vh;
        width: 50vw;
        background-position: center 80%;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.8;
        align-items: center;
    }

    .imgAboutTxt {
        padding-left: 20%;
        height: 90vh;
        align-self: center;
        background-color: #00000086;
        display: flex;
        align-items: center;
    }

.imgAboutTxt h1 {
    max-width: calc(100px + 30vw);
}

.subheadAbout {
    max-width: calc(100px + 25vw);
    font-size: calc(10px + 0.8vw);
    letter-spacing: 0.06em;
}

.ourWork {
    padding-left: 20%;
    height: 70vh;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.ourWorkImg {
    height: 60vh;
}

.txtAbout {
    display: flex;
    height: 90vh;
    padding-top: 10vh;
    align-items: center;
    padding-left: 10%;
}
    .ourWorkImg .imgCollage {
        height: 80vh;
        display: flex;
        width: 55vw;
        margin-top: -13vh;
        margin-left: -5.5vw;
    }

    .splitWork {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-row-gap: 0px;
        align-items: center;
        text-align: left;
        height: 80vh;
        margin-top: -5vh;
    }

    .leftceo {
        padding-left: 10%;
        padding-right: 20%;
    }

    .ceoImg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 80%;
        height: 60vh;
        margin-left: 10vw;
    }

    .ceoTxt {
        grid-area: txt;
        width: 60%;
        text-align: left;
        padding-left: 5%;
        margin-top: 50vh;
        font-style: italic;
        letter-spacing: 0.04em;
        line-height: 24px;
        font-weight: normal;
    }

}

@media only screen and (max-width: 1000px) {

    #bouncingText{
        display: none;
    }



.imgAbout {
    height: 70vh;
    margin-top: 11vh;
    width: 100vw;
    background-image: url("/src/components/img/industryview.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.8;
    align-items: center;
}

.imgAboutTxt {
    padding: 10px;
padding-top: 20vh;
    height: 49vh;
    background-color: #00000086;
    display: flex;
    align-items: top;
}

.txtAbout {

   position: absolute;
    height: 50vh;
    width: 90vw;
    top: 50vh;
left: 5%;
z-index: 2;
    align-items: center;
    justify-content: center;
   
}

    .ourWork {
        width: 75%;
        padding: 1em;
        padding-top: 10vh;
        text-align: left;
        padding-left: 10%;
        height: calc(250px + 2vh);
    }

    .ourWorkImg {
        height: 25vh;
        display: flex;
        flex-direction: row;
        width: 90%;
        margin-bottom: 3%;
    }

    .leftceo {
        max-width: 80vw;
        justify-items: center;
        padding-left: 2vw;
        text-align: left;

    }


    .ceoImg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 2vw;
        width: 80vw;
        height: 300px;
        align-self: center;
        justify-self: center;
        margin-top: 10px;
        display: flex;
    }

    .ceoTxt {
       
        width: 90%;
        text-align: left;
        font-family: tit-reg;
        font-size: 14px;
        font-style: italic;
        letter-spacing: 0.04em;
        line-height: 24px;
        font-weight: normal;
        padding-left: 10px;
        margin-bottom: 10px;
        align-self: flex-end;
    }

    .imgCollage{
        width: 113vw;
        left: -13vw;
        height: 50vh;
        position: absolute;
        top: 57vh;
        transform: rotate(-0deg);
        background-position: right + 10px;
        background-size:130%;
        background-repeat: no-repeat;
      
    }

    .leftceo h2{

        margin-top: -390px;
        margin-bottom: 355px;

    }


}