

.cormapscanner {
    background-image: url('/src/components/img/cormap.png');
}




.wallmap {
    background-image: url('/src/components/img/corrmapbkg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.wallmapping {

    text-align: center;


}






.threedCor {
    background-image: url('/src/components/img/3d+mapCor.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



.cormapact{
    background-image: url('/src/components/img/corrmapinginaction.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

@media only screen and (min-width: 1000px) {

    .leftsolcormap {
        width: 35vw;
    }

    .corrSection1 {
        height: 170vh;
        margin-top: 6vh;
        
    }


.cormapscanner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
    width: calc(65px + 25vw);
    position: absolute;
    z-index: 5;
    top: 2vh;
    align-self: center;
    left: 57vw;
    justify-self: center;
    transform: rotate(-8deg);
    
}


.cormapact{
    height: 35vh;
    width: 40vw;
    margin-top: 10vh;
    
}


.wallmap {
    height: 55vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0vw;
    width: 100vw;
    margin-top: 18vh;

}

.wallmapping h3 {
    margin-left: 0;
}

.corReporting {
    height: 65vh;
    display: flex;
    flex-direction: row;
    margin-left: 17vw;
    align-items: top;
    width: 70vw;
    justify-content: space-evenly;
    margin-top: -18vh;
    align-items: center;



}

.reporttxt {
    width: 28vw;
    text-align: left;

}

.reporttxt h5 {
    line-height: calc(20px + 2.3vh);
}

.threedCor {
    height: 42vh;
    width: 60vh;
    margin-left: 1vw;
}


}
@media only screen and (max-width: 1000px) {
    .cormapscanner {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 8vh;
        width: 20vh;
        position: absolute;
        z-index: 5;
        top: 40vh;
        align-self: center;
        left: 60vw;
        justify-self: center;
        transform: rotate(290deg);
    }

    .corrSection1 {
        height: fit-content;
        padding-top: 10vh;
        
    }

    .cormapact{
        height: 35vh;
        width: 80vw;
        margin-top: 1vh;
        margin-left: 10vw;
        
    }

    .wallmap {
        height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0vw;
        width: 100vw;
        margin-top: 2vh;
    
    }

    .wallmapping h3 {
        margin-left: -4vw;
    }

    .corReporting {
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-left: 10vw;
        align-items: top;
        width: 80vw;
        justify-content: space-evenly;
        margin-top: 5vh;
        align-items: center;
    }
    .reporttxt {
        width: 100%;
        text-align: left;
    
    }
    
    .reporttxt h5 {
        line-height: calc(20px + 1vh);
    }


    .threedCor {
        height: 25vh;
        width: 100%;
       margin-bottom: 10vh;
       background-size: contain;
    }
    

}